import React from 'react'
import BackgroundImage from 'gatsby-background-image'
import { Link, useStaticQuery, graphql } from 'gatsby'
import ExternalLink from '../utils/external-link'

import styles from '../css/mobile-menu.module.css'

import HomeIcon from '../images/rarechicagomenuicon.png'

import { AboutSVG, ServicesSVG, ContactSVG, FacebookSVG, InstagramSVG, PhoneSVG, MailSVG, PrivacySVG } from './svgs'

const MobileMenu = ({ mobileMenu }) => {
    const backgroundImage = useStaticQuery(graphql`
    query {
        file(name: {eq: "rarechicagocloudsbackground"}) {
          childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `)

    const fluid = backgroundImage.file.childImageSharp.fluid

    console.log(backgroundImage);

    console.log(backgroundImage.file.childImageSharp.fixed)

    const NavItem = ({ SVG, slug, label }) => (
        <li className="mb-4">
            <Link to={slug} className="flex items-center text-dkpurple">
                <SVG className="fill-current w-8" />
                <p className="inline-block ml-4">{label}</p>
            </Link>
        </li>
    )

    const LinkItem = ({ SVG, LinkType, href, label }) => (
        <li className="mb-4">
            <LinkType href={href} className="flex items-center text-dkpurple">
                <SVG className="fill-current w-8" />
                <p className="inline-block ml-4">{label}</p>
            </LinkType>
        </li>
    )

    return(
        <div
            className={`${styles.menuContainer} bg-white shadow-xl laptop:hidden`}
            id={mobileMenu === true ? styles.menuOpen : mobileMenu === false ? styles.menuClose : ''}
        >

            <BackgroundImage style={{ paddingTop: '1rem', height: '100%' }} fluid={fluid}>

            <Link
                to="/"
            >
                <img
                    className="ml-4"
                    src={HomeIcon}
                    style={{ width: '64px', height: '64px'}}
                    alt="Rare Chicago square icon with large R in center - Link to home page"
                />
            </Link>

            <ul className="mt-6 pl-4">
                <NavItem
                    SVG={AboutSVG}
                    slug="/about/"
                    label="About Us"
                />
                <NavItem
                    SVG={ServicesSVG}
                    slug="/services/"
                    label="Services"
                />
                <NavItem
                    SVG={ContactSVG}
                    slug="/contact/"
                    label="Contact"
                />
            </ul>

            <div className="w-full px-2 opacity-20">
                <hr className="bg-dkpurple my-8 h-2px" />
            </div>

            <ul className="mt-2 pl-4 text-xs opacity-80 pb-16">
                <LinkItem
                    SVG={FacebookSVG}
                    LinkType={ExternalLink}
                    href="https://www.facebook.com/rarechicagotech"
                    label="@rarechicagotech"
                />
                <LinkItem
                    SVG={InstagramSVG}
                    LinkType={ExternalLink}
                    href="https://www.instagram.com/rarechicagotech"
                    label="@rarechicagotech"
                />
                <LinkItem
                    SVG={PhoneSVG}
                    LinkType='a'
                    href="tel:(+13129998388)"
                    label="(312) 999-8388"
                />
                <LinkItem
                    SVG={MailSVG}
                    LinkType='a'
                    href="mailto: hello@rarechicago.com"
                    label="hello@rarechicago.com"
                />
                <NavItem
                    SVG={PrivacySVG}
                    slug="/privacy/"
                    label="Privacy Policy"
                />
            </ul>

            </BackgroundImage>

        </div>
    )
}

export default MobileMenu;