import React from 'react'
import { Link } from "gatsby"
import Image from './image-provider'
import ExternalLink from '../utils/external-link'

import { FacebookSVG, InstagramSVG, TwitterSVG, LinkedinSVG, GitHubSVG, HeartSVG, GotProjectSVG, PhoneSVG, MailSVG } from './svgs'

const Footer = () => {

    const SocialIcon = ({ SVG, LinkType, href }) => (
        <LinkType href={href} className="flex items-center">
            <div className="w-8">
                <SVG className="fill-current text-mdgray" />
            </div>
        </LinkType>
    )

    return(
        <footer className="w-full bg-dkpurple absolute z-20">

            <div className="max-viewport mx-auto flex flex-wrap pt-20 pb-12 laptop:pt-12">

                <div className="w-full laptop:w-3/4 laptop:flex items-center">

                    <Image
                        fileName="rarechicagobanner.png"
                        className="w-11/12 mx-auto laptop:hidden"
                        alt="Rare Chicago Digital Marketing & Web Development banner"
                        style={{ maxWidth: '600px' }} 
                    />

                    <GotProjectSVG className="hidden laptop:block ml-4" />

                </div>

                <div className="w-full laptop:w-1/4 laptop:flex justify-end">
                    <Image
                        fileName="rarechicagocloudsgold.png"
                        className="w-4/5 mx-auto mt-24 laptop:mt-0 laptop:mx-0 laptop:mr-4"
                        alt="Rare Chicago gold clouds logo"
                        style={{ maxWidth: '420px' }}
                    />
                </div>

                <div className="w-full mt-24 laptop:hidden">
                    <div className="w-2/3 mx-auto flex justify-around my-4" style={{ maxWidth: '375px' }}>
                        <SocialIcon
                            SVG={FacebookSVG}
                            LinkType={ExternalLink}
                            href="https://www.facebook.com/rarechicagotech"
                        />
                        <SocialIcon
                            SVG={InstagramSVG}
                            LinkType={ExternalLink}
                            href="https://www.instagram.com/rarechicagotech"
                        />
                        <SocialIcon
                            SVG={TwitterSVG}
                            LinkType={ExternalLink}
                            href="https://www.twitter.com/rarechicagotech"
                        />
                        <SocialIcon
                            SVG={LinkedinSVG}
                            LinkType={ExternalLink}
                            href="https://www.linkedin.com/company/rarechicago"
                        />
                        <SocialIcon
                            SVG={GitHubSVG}
                            LinkType={ExternalLink}
                            href="https://github.com/rarechicago"
                        />
                    </div>
                </div>

                <div className="hidden laptop:flex w-full mt-12">

                    <div className="w-3/4 text-white pl-4">

                        <div className="flex items-center">
                            <PhoneSVG className="fill-current text-white w-8" />
                            <p className="ml-2">312.999.8388</p>
                        </div>
                        <div className="flex items-center mt-2">
                            <MailSVG className="fill-current text-white w-8" />
                            <p className="ml-2">hello@rarechicago.com</p>
                        </div>

                    </div>

                    <div className="w-1/4 flex justify-end self-end">
                        <div className="flex w-5/6 justify-around text-ltgray font-semibold text-sm mr-4">
                            <Link to="/about/">
                                About Us
                            </Link>
                            <Link to="/services/">
                                Services
                            </Link>
                            <Link to="/contact/">
                                Contact
                            </Link>
                        </div>
                    </div>

                </div>

                <div className="hidden laptop:flex w-full px-4 mt-12 opacity-20">
                    <hr className="w-full mx-auto bg-white h-px" />
                </div>

                <div className="flex w-full">

                    <div className="w-full text-xs laptop:flex laptop:w-3/4 items-center laptop:mt-16 laptop:text-tiny">
                        <p className="mt-20 text-dkgray text-center laptop:mt-0 laptop:ml-4">&#169; 2021 Rare Chicago LLC all rights reserved</p>
                        <p className="text-dkgray text-center mt-8 laptop:mt-0 laptop:ml-4">SITE DESIGNED AND DEVELOPED BY RARE</p>

                        <Link
                            to="/privacy/"
                            className="hidden laptop:block text-dkgray ml-4"
                        >
                            Privacy Policy
                        </Link>
                        
                        <div className="flex w-full justify-around mt-8 laptop:mt-0 laptop:block laptop:w-36 laptop:ml-4">
                            <div className="flex items-center text-dkgray text-xs laptop:text-tiny">
                                <p>Made with</p>
                                    <HeartSVG />
                                <p>in Chicago</p>
                            </div>
                        </div>
                    </div>

                    <div className="hidden laptop:flex w-1/4 items-end justify-end">
                        <div className="flex justify-around w-5/6 laptop:mr-4" style={{ maxWidth: '220px' }}>
                            <SocialIcon
                                SVG={FacebookSVG}
                                LinkType={ExternalLink}
                                href="https://www.facebook.com/rarechicagotech"
                            />
                            <SocialIcon
                                SVG={InstagramSVG}
                                LinkType={ExternalLink}
                                href="https://www.instagram.com/rarechicagotech"
                            />
                            <SocialIcon
                                SVG={TwitterSVG}
                                LinkType={ExternalLink}
                                href="https://www.twitter.com/rarechicagotech"
                            />
                            <SocialIcon
                                SVG={LinkedinSVG}
                                LinkType={ExternalLink}
                                href="https://www.linkedin.com/company/rarechicago"
                            />
                            <SocialIcon
                                SVG={GitHubSVG}
                                LinkType={ExternalLink}
                                href="https://github.com/rarechicago"
                            />
                        </div>
                    </div>


                </div>

            </div>

        </footer>
    )
}

export default Footer;