import { Link } from "gatsby"
import React from "react"

import Image from './image-provider'

import styles from '../css/header.module.css'

const Header = ({ mobileMenu, setMobileMenu }) => (
  <header className={`fixed ${mobileMenu === true ? `z-30` : `z-10`} top-0 left-0 w-full bg-dkpurple bg-opacity-90`}>
    <div className={`${styles.header} flex items-center justify-between max-viewport mx-auto px-4`}>

        <Link
          to="/"
        >
            <Image
                fileName="rarechicagocloudsgray.png"
                className="w-20"
                alt="Rare Chicago clouds logo - Link to home page"
            />
        </Link>

        <div>
          <button
              className="block grid grid-cols-1 gap-y-0 laptop:hidden"
              aria-label="Open/close navigation menu"
              onClick={() => setMobileMenu(!mobileMenu)}
          >
              <div className={styles.bar1} id={mobileMenu ? styles.changebar1 : "bar1"}></div>
              <div className={styles.bar2} id={mobileMenu ? styles.changebar2 : "bar2"}></div>
              <div className={styles.bar3} id={mobileMenu ? styles.changebar3 : "bar3"}></div>
          </button>
        </div>

        <nav className="hidden laptop:flex text-ltgray font-semibold">
            <Link 
                to="/about/"
                className="mr-8"
            >
              About Us
            </Link>
            <Link
                to="/services/"
                className="mr-8"
            >
              Services
            </Link>
            <Link to="/contact/"
                  className="mr-4"
            >
              Contact
            </Link>
        </nav>

    </div>
  </header>
)

export default Header;
