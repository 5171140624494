import React, { useState } from "react"

import Header from "./header"
import MobileMenu from './mobile-menu'
import Footer from './footer'
import "../css/layout.css"

import Amplify from '@aws-amplify/core';
import awsconfig from '../aws-exports';
Amplify.configure(awsconfig);

const Layout = ({ children }) => {

  const [mobileMenu, setMobileMenu] = useState('');

  return (
      <>
        <Header
          mobileMenu={mobileMenu}
          setMobileMenu={setMobileMenu}
        />

        { mobileMenu === true && <div className="mobile-shade" /> }

        <MobileMenu
            mobileMenu={mobileMenu}
        />

        <main>{children}</main>

        <Footer />
      </>
  )
}

export default Layout;
